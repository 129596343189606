// libraries
import parse from 'html-react-parser'
import { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'

// components
import { apiClient } from '../../core/api_client'
import Breadcrumb from "../../components/Breadcrumb"
import l from '../../core/localize';
import { Routing } from '../../core/routing'


// assets
import pdf22 from '../../../assets/files/menzil sechimi statistikasi/report24.09.2022.pdf'
import pdf24 from '../../../assets/files/menzil sechimi statistikasi/report16.12.2023.pdf'
import updated_label_az from '../../../assets/img/updated_label_az.svg';

const MenzilSechimiStat = () => {
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)

    const breadcrumb = [
        {
            id: 1,
            link: '/agentlik/haqqimizda',
            title: t('agentlik:agentlik'),
            isActive: false
        },
        {
            id: 2,
            link: '/agentlik/hesabatlar',
            title: t('agentlik:audits'),
            isActive: false
        },
        {
            id: 3,
            link: '/agentlik/hesabatlar/satis',
            title: t('agentlik:audits_sales'),
            isActive: true
        }
    ]

    const initData = [
        {
            id: 24,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 16.12.2023",
            pdf: pdf24
        },
        {
            id: 23,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 29.07.2023",
            pdf: ""
        },
        {
            id: 22,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 24.09.2022",
            pdf: pdf22
        },
        {
            id: 21,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 17.08.2021",
            pdf: ""
        },
        {
            id: 20,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 01.07.2021",
            pdf: ""
        },
        {
            id: 19,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 01.07.2021",
            pdf: ""
        },
        {
            id: 18,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 18.12.2020",
            pdf: ""
        },
        {
            id: 17,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 18.12.2020",
            pdf: ""
        },
        {
            id: 16,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 10.09.2020",
            pdf: ""
        },
        {
            id: 15,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 10.09.2020",
            pdf: ""
        },
        {
            id: 14,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 30.06.2020",
            pdf: ""
        },
        {
            id: 13,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 30.06.2020",
            pdf: ""
        },
        {
            id: 12,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 25.12.2019",
            pdf: ""
        },
        {
            id: 11,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 25.12.2019",
            pdf: ""
        },
        {
            id: 10,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 16.10.2019",
            pdf: ""
        },
        {
            id: 9,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 16.10.2019",
            pdf: ""
        },
        {
            id: 8,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 12.07.2019",
            pdf: ""
        },
        {
            id: 7,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 12.07.2019",
            pdf: ""
        },
        {
            id: 6,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 29.04.2019",
            pdf: ""
        },
        {
            id: 5,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 29.04.2019",
            pdf: ""
        },
        {
            id: 4,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 25.01.2019",
            pdf: ""
        },
        {
            id: 3,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 25.01.2019",
            pdf: ""
        },
        {
            id: 2,
            text: "Seçilmiş mənzillər barədə statistik məlumatlar <br/> 05.09.2018",
            pdf: ""
        },
        {
            id: 1,
            text: "Mənzil seçimi etmiş vətəndaşlara dair statistik məlumatlar <br/> 05.09.2018",
            pdf: ""
        }
    ];
    const [audit_card, setAuditCard] = useState(initData);

    useEffect(() => {
        apiClient.get('/reports?report_type=APARTMENT_CHOOSE').then(res => {
            console.log(res.data)
            const data = res.data.map(x => ({
                id: x.id,
                text: l(x, "title", currentLang),
                pdf: Routing.generateFileRoute(x.file)
            }));
            setAuditCard([...data.reverse(), ...initData]);
        })
    }, [currentLang])


    useEffect(() => {
        console.log(audit_card[0].pdf)
    }, [audit_card])

    const [page, setPage] = useState(0)
    const itemsOnPage = 15
    const paginate = true


    let totalPages = Math.ceil(audit_card.length / itemsOnPage)
    let firstActivePage = page - 2
    if (firstActivePage < 1) {
        firstActivePage = 1
    } else if (totalPages - firstActivePage < 4) {
        firstActivePage = totalPages - 4
    }
    let activePages = []
    for (let i = firstActivePage; i <= Math.min(firstActivePage + 4, totalPages); i++) {
        activePages.push(i)
    }

    return (
        <div className="audit-page block-bg1">
            <Breadcrumb data={breadcrumb} className="mb-0" style={{ height: 0 }} />
            <div className='bg-picture2'>
                <div className='container'>
                    <div className='row justify-content-between pt-60 pb-120'>
                        {audit_card.slice(page * itemsOnPage, (page + 1) * itemsOnPage).map(item =>
                            item.pdf && item.pdf.split('.').pop().toLowerCase() === "pdf" ?
                                <div key={item.id} className='audit-card rounded-4 my-4 cursor px-2 col-12 position-relative' style={{ backgroundColor: "#223A5E", width: "31%", height: '100%' }}>
                                    {/* <img className='position-absolute status-label-flag' src={updated_label_az} alt="Yenilənib" /> */}

                                    <a href={item.pdf} rel="noreferrer" key={item.id} target="_blank">
                                        <div className='audit-card_side audit-card_side_back px-4 py-4 text-center'>
                                            <p className='t-color4 mb-0'>{parse(item.text)}</p>
                                        </div>

                                        <div className='audit-card_side audit-card_side_front px-5 text-center'>
                                            <p className='py-5 t-color4 mb-0'>{parse(item.text)}</p>
                                        </div>
                                    </a>
                                </div>
                                :
                                <div key={item.id} className='audit-card rounded-4 my-4 cursor px-2 col-12 position-relative' style={{ backgroundColor: "#223A5E", width: "31%", height: '100%' }}>
                                    <a href={'/agentlik/hesabatlar/satis/menzil-secimi-statistikasi/' + item.id} rel="noreferrer" key={item.id}>
                                        <div className='audit-card_side audit-card_side_back px-4 py-4 text-center'>
                                            <p className='t-color4 mb-0'>{parse(item.text)}</p>
                                        </div>

                                        <div className='audit-card_side audit-card_side_front px-5 text-center'>
                                            <p className='py-5 t-color4 mb-0'>{parse(item.text)}</p>
                                        </div>
                                    </a>
                                </div>
                        )}

                        {audit_card.length === 0 && <h4 className="col-12 text-center">Məlumat tapılmadı</h4>}
                        {paginate && audit_card.length > 0 && <div className="col-12">
                            <div className="pagination d-flex justify-content-center">
                                <button className="btn btn-outline-primary" disabled={page === 0} onClick={() => setPage(0)}>&lt;&lt;</button>
                                <button className="btn btn-outline-primary" disabled={page === 0} onClick={() => setPage(page - 1)}>&lt;</button>
                                {activePages.map(item =>
                                    <button
                                        className={`btn btn-outline-primary ${item - 1 === page ? 'active' : ''}`}
                                        disabled={item - 1 === page}
                                        onClick={() => setPage(item - 1)}
                                        key={item}
                                    >
                                        {item}
                                    </button>
                                )}
                                <button className="btn btn-outline-primary" disabled={page === totalPages - 1} onClick={() => setPage(page + 1)}>&gt;</button>
                                <button className="btn btn-outline-primary" disabled={page === totalPages - 1} onClick={() => setPage(totalPages - 1)}>&gt;&gt;</button>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenzilSechimiStat 