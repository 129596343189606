import { useEffect, useState } from 'react'

import { apiClient } from '../../../core/api_client';

import Template1 from '../templates/Template1';
import Template2 from '../templates/Template2';
import Template3 from '../templates/Template3';
import Template5 from '../templates/Template5';
import Template6 from '../templates/Template6';
import Template7 from '../templates/Template7';
import Template8 from '../templates/Template8';
import Template9 from '../templates/Template9';
import Template10 from '../templates/Template10';
import Template11 from '../templates/Template11';
import Template12 from '../templates/Template12';
import Template13 from '../templates/Template13';
import Template14 from '../templates/Template14';
import Template4 from '../templates/Template4';
import Template16 from '../templates/Template16';
import Template7_1 from '../templates/Template7_1';
import Template17 from '../templates/Template17';


const SatisStats = ({ id }) => {
    const [data, setData] = useState()
    const [projectsData, setProjectsData] = useState()


    useEffect(() => {
        // console.log("id in comp " + id)
        apiClient.get("/reports/" + id)
            .then(res => {
                console.log("res ", res.data)
                var temp = res.data
                temp.templates = JSON.parse(temp.templates)

                return temp.templates;
            })
            .then(data => {
                console.log("data >>> ", data);
                setData(data.general)
                setProjectsData(data.projects)
            }).catch(err => console.log(err))
    }, [id])

    return (
        <div className='mt-3'>
            {data &&
                <>
                    <Template1 data={data.template1} />
                    <Template2 data={data.template2} />
                    {projectsData.map(proj =>
                        <div key={projectsData.indexOf(proj)}>
                            <Template2 data={proj.template2} projectName={proj.projectName} />
                        </div>
                    )}
                    <Template3 data={data.template3} />
                    {projectsData.map(proj => {
                        return projectsData.indexOf(proj) == 3 ? <></> :
                            <div key={projectsData.indexOf(proj)}>
                                <Template3 data={proj.template3} projectName={proj.projectName} />
                            </div>
                    }
                    )}
                    <Template4 data={data.template4} />
                    <Template5 data={data.template5} />
                    {projectsData.map(proj => {
                        return projectsData.indexOf(proj) == 3 ? <></> :
                            <div key={projectsData.indexOf(proj)}>
                                <Template5 data={proj.template5} projectName={proj.projectName} />
                            </div>
                    }
                    )}
                    <Template6 data={data.template6} />
                    {data.template16.map(proj => {

                        <div key={data.template16.indexOf(proj)}>
                            <Template16 data={proj} />
                        </div>
                    }
                    )}
                    <Template7_1 data={data.template7} />
                    <Template7 data={data.template7} />
                    {projectsData.map(proj => {
                        return projectsData.indexOf(proj) == 3 ? <></> :
                        <div key={projectsData.indexOf(proj)}>
                            <Template7 data={proj.template7} projectName={proj.projectName} />
                        </div>
                    }     
                    )}
                    <Template8 data={data.template8} />
                    <Template9 data={data.template15} templateData={data.template9}/>
                    <Template11 data={data.template11} />
                    <Template12 data={data.template12} />
                    <Template10 data={data.template10} />
                    {projectsData.map(proj =>
                        <div key={projectsData.indexOf(proj)}>
                            <Template10 data={proj.template10} projectName={proj.projectName} />
                        </div>
                    )} 
                    {/* <Template15 data={data.template10} />
                    <Template18 data={projectsData} templateData={data.template10}/>*/}
                    <Template17 data={data.template17} /> 
                    <Template13 data={data.template13} />
                    <Template14 data={data.template14} />
                  
                </>
            }
        </div>
    );
}

export default SatisStats;
