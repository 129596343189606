import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// dayjs.extend(customParseFormat);

const Template0 = ({ data, project }) => {
	const [firstPrev, setFirstPrev] = useState()
	const [lastPrev, setLastPrev] = useState()
	const [tables, setTables] = useState()


	useEffect(() => {
		setFirstPrev(data.firstPreview)
		setLastPrev(data.lastPreview)
		setTables(data.tables)
	}, [data])

	return (
		<div className='py-5 px-2'>
			<div className='temp1-stats my-4'>
				{project.total_cabinets &&
					<div className="d-flex align-items-center justify-content-between">
						<h5 className="block-bg4 text-white px-2 py-3 col-11">"Güzəştli mənzil" sistemində qeydiyyatdan keçərək elektron kabinetə sahib olan vətəndaşların sayı</h5>
						<h5 className='bg-highlight text-center text-white p-3 col-1'>{project.total_cabinets}</h5>
					</div>
				}
				{project.cabinets_participated &&
					<div className="d-flex align-items-center justify-content-between">
						<h5 className="block-bg4 text-white col-11 px-2 py-3">Satış zamanı mənzil seçimində iştirak edən istifadəçilərin sayı</h5>
						<h5 className='bg-highlight text-center text-white py-3 px-4 col-1'>{project.cabinets_participated}</h5>
					</div>
				}
			</div>
			<div className='row d-flex justify-content-between'>
				<div className="col-lg-6">
					<h5 className='text-center w-100'>{project.notes[0].project_name}də baxış keçirilən ilk mənzil</h5>
					<table className='table table-bordered'>
						{data && firstPrev &&
							<tbody>
								<tr>
									<td>Bina №</td>
									<td>{firstPrev.buildingNumber}</td>
								</tr>
								<tr>
									<td>Giriş №</td>
									<td>{firstPrev.entranceNumber}</td>
								</tr>
								<tr>
									<td>Mərtəbə №</td>
									<td>{firstPrev.floorNumber}</td>
								</tr>
								<tr>
									<td>Mənzil №</td>
									<td>{firstPrev.apartmentNumber}</td>
								</tr>
								<tr>
									<td>Otaq sayı</td>
									<td>{firstPrev.roomsCount}</td>
								</tr>
								<tr>
									<td>Mənzilə baxış keçirilməsinin başlama vaxtı</td>
									<td>{dayjs(firstPrev.previewOn).format('YYYY-MM-DD HH:mm:ss')}</td>
								</tr>
								<tr>
									<td>Mənzil seçminin təsdiqləmə vaxtı</td>
									<td>{dayjs(firstPrev.confirmOn).format('YYYY-MM-DD HH:mm:ss')}</td>
								</tr>
								<tr>
									<td>Mənzil seçimi ərizəsinin imzalanma vaxtı</td>
									<td>{dayjs(firstPrev.signatureOn).format('YYYY-MM-DD HH:mm:ss')}</td>
								</tr>
								<tr>
									<td>Qeyd</td>
									<td>{project.notes[0].first_preview_note}</td>
								</tr>
							</tbody>
						}
					</table>
				</div>
				<div className="col-lg-6">
					<h5 className='text-center w-100'>{project.notes[0].project_name}də baxış keçirilən son mənzil</h5>
					<table className='table table-bordered'>
						{data && lastPrev &&
							<tbody>
								<tr>
									<td>Bina №</td>
									<td>{lastPrev.buildingNumber}</td>
								</tr>
								<tr>
									<td>Giriş №</td>
									<td>{lastPrev.entranceNumber}</td>
								</tr>

								<tr>
									<td>Mərtəbə №</td>
									<td>{lastPrev.floorNumber}</td>
								</tr>
								<tr>
									<td>Mənzil №</td>
									<td>{lastPrev.apartmentNumber}</td>
								</tr>
								<tr>
									<td>Otaq sayı</td>
									<td>{lastPrev.roomsCount}</td>
								</tr>
								<tr>
									<td>Mənzilə baxış keçirilməsinin başlama vaxtı</td>
									<td>{dayjs(lastPrev.previewOn).format('YYYY-MM-DD HH:mm:ss')}</td>
								</tr>
								<tr>
									<td>Mənzil seçminin təsdiqləmə vaxtı</td>
									<td>{dayjs(lastPrev.confirmOn).format('YYYY-MM-DD HH:mm:ss')}</td>
								</tr>
								<tr>
									<td>Mənzil seçimi ərizəsinin imzalanma vaxtı</td>
									<td>{dayjs(lastPrev.signatureOn).format('YYYY-MM-DD HH:mm:ss')}</td>
								</tr>
								<tr>
									<td>Qeyd</td>
									<td>{project.notes[0].last_preview_note}</td>
								</tr>
							</tbody>
						}
					</table>
				</div>
			</div>
			{data && tables &&
				tables.map(table =>
					<>
						<h5 className='text-center w-100 mt-5 pt-5'>{table.project}</h5>
						<table className='table table-bordered'>
							<thead>
								<tr style={{ verticalAlign: 'middle' }}>
									<th>Bina №</th>
									<th>Giriş №</th>
									<th>Mərtəbə</th>
									<th>Mənzil №</th>
									<th>Otaq sayı</th>
									<th>Ödəniş üsulu</th>
									<th>Satış qiyməti</th>
									<th>Mənzilə baxış keçirilməsinin başlama vaxtı</th>
									<th>Mənzil seçminin təsdiqləmə vaxtı</th>
									<th>İmzalanma vaxtı</th>
									<th>Mənzil seçimi ərizəsinin imzalanma vaxtı</th>
								</tr>
							</thead>
							<tbody>
								{table.rows.map(row =>
									<tr key={table.rows.indexOf(row)}>
										<td>{row.buildingNumber}</td>
										<td>{row.entranceNumber}</td>
										<td>{row.floorNumber}</td>
										<td>{row.apartmentNumber}</td>
										<td>{row.roomsCount}</td>
										<td>{row.paymentType === "Loan" ? "İpoteka krediti hesabına" : "Öz vəsaiti hesabına"}</td>
										<td>{row.price}</td>
										<td>{dayjs(row.previewOn).format('YYYY-MM-DD HH:mm:ss')}</td>
										<td>{dayjs(row.confirmOn).format('YYYY-MM-DD HH:mm:ss')}</td>
										<td>{dayjs(row.signatureOn).format('YYYY-MM-DD HH:mm:ss')}</td>
										<td>{row.category}</td>
									</tr>
								)}
							</tbody>
						</table>
					</>)}
		</div>
	)
}

export default Template0 
