import { useEffect, useState } from 'react'
import Template0 from '../templates/Template0'
import { apiClient } from '../../../core/api_client'

const MenzilStats = ({ id }) => {
    const [data, setData] = useState()
    const [projectsData, setProjectsData] = useState()


    useEffect(() => {
        console.log("id in comp " + id)
        apiClient.get("/reports/" + id)
            .then(res => {
                console.log("res ",res.data)
                setProjectsData(res.data)
                var temp = res.data
                temp.templates = JSON.parse(temp.templates)

                return temp.templates;
            })
            .then(data => {
                console.log("data >>> ", data);
                setData(data.general)
                // setProjectsData(data.projects)
            }).catch(err => console.log(err))
    }, [id])

    return (
        <div className='mt-3'>
            {data && <Template0 data={data.template0} project={projectsData} />}
        </div>
    )
}

export default MenzilStats 
