import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Map = ({ projects }) => {
  const [map, setMap] = useState(null);
  const { t, i18n } = useTranslation()
  // const currentLang = i18n.language.slice(0, 2)

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoib2ZlbGlhcnYiLCJhIjoiY20xejJ1dWhhMDJpZzJpczhzMnJvZjdmMyJ9.yXFL1Nfhvw2sld9yPx8tLA';

    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [47.912, 40.173],
      zoom: 7
    });

    mapInstance.on('load', function () {

      // YASAMAL
      mapInstance.addSource('300004', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': { 'name': 'Yasamal' },
          "geometry": {
            "type": "Polygon",
            "coordinates": [
              [
                [49.795371, 40.377844],
                [49.795509, 40.378016],
                [49.795495, 40.378160],
                [49.795905, 40.378309],
                [49.796834, 40.378354],
                [49.797776, 40.378400],
                [49.798427, 40.376875],
                [49.799079, 40.375360],
                [49.797651, 40.375480],
                [49.796212, 40.375609],
                [49.795906, 40.374921],
                [49.795070, 40.374904],
                [49.794234, 40.374895],
                [49.794361, 40.375680],
                [49.793143, 40.375965]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        'id': 'yasamal_zone',
        'type': 'fill',
        'source': '300004',
        'paint': {
          'fill-color': '#FFB900',
          'fill-opacity': 0.6
        }
      });

      // YASAMAL 2
      mapInstance.addSource('100001', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Yasamal 2',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.799553, 40.375166],
                [49.799958, 40.374261],
                [49.801283, 40.372599],
                [49.796549, 40.371216],
                [49.795703, 40.371117],
                [49.795442, 40.371232],
                [49.795318, 40.372240],
                [49.795951, 40.372408],
                [49.795987, 40.374985],
                [49.796393, 40.375350],
                [49.796722, 40.375416]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'yasamal2_zone',
        type: 'fill',
        source: '100001',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // HOVSAN +
      mapInstance.addSource('300001', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Hövsan',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [50.073522, 40.381974],
                [50.074532, 40.378793],
                [50.066448, 40.378125],
                [50.067925, 40.381200]
              ],
            ],
          },
        },
      });

      mapInstance.addLayer({
        id: 'hovsan_zone',
        type: 'fill',
        source: '300001',
        layout: {},
        paint: {
          'fill-color': '#FFB900',
          'fill-opacity': 0.6,
        }
      });

      // HOVSAN 2 +
      mapInstance.addSource('300005', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Hövsan 2',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [50.072542, 40.389057],
                [50.073576, 40.384065],
                [50.069047, 40.383569],
                [50.069394, 40.384067],
                [50.068624, 40.385178],
                [50.067185, 40.386203]
              ],
            ],
          },
        },
      });

      mapInstance.addLayer({
        id: 'hovsan2_zone',
        type: 'fill',
        source: '300005',
        layout: {},
        paint: {
          'fill-color': '#FFB900',
          'fill-opacity': 0.6,
        }
      });

      // GANJA +
      mapInstance.addSource('1', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Gəncə',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [46.376378, 40.683605],
                [46.377880, 40.682416],
                [46.376371, 40.680921],
                [46.375786, 40.681261],
                [46.374747, 40.680229],
                [46.373402, 40.681019]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'ganja_zone',
        type: 'fill',
        source: '1',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // SUMQAYIT (YK) +
      mapInstance.addSource('300017', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Sumqayit',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.674422, 40.604737],
                [49.675724, 40.605256],
                [49.675748, 40.605229],
                [49.676587, 40.604023],
                [49.675369, 40.603468]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'sumqait_zone',
        type: 'fill',
        source: '300017',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // SUMQAYIT 2 (Guzewtli menzil) +
      mapInstance.addSource('200002', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Sumqayit 2',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.667565, 40.581614],
                [49.668231, 40.580820],
                [49.667616, 40.580236],
                [49.667094, 40.580933],
                [49.667422, 40.581072],
                [49.667143, 40.581438]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'sumqait_zone2',
        type: 'fill',
        source: '200002',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // LANKARAN +
      mapInstance.addSource('300016', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Lankaran',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [48.840196, 38.770326],
                [48.840603, 38.769432],
                [48.839513, 38.769304],
                [48.839397, 38.768968],
                [48.839212, 38.768992],
                [48.838920, 38.769131],
                [48.838588, 38.769458],
                [48.838192, 38.769622]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'lankaran_zone',
        type: 'fill',
        source: '300016',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // BINAQADI +
      mapInstance.addSource('300011', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Binaqadi',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [49.815406, 40.454590],
                [49.816947, 40.451461],
                [49.816807, 40.451379],
                [49.816562, 40.451205],
                [49.814027, 40.451215],
                [49.813536, 40.451634],
                [49.813154, 40.452549],
                [49.812687, 40.453616],
                [49.813039, 40.453719],
                [49.812941, 40.453943]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'binaqadi_zone',
        type: 'fill',
        source: '300011',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // SHIRVAN +
      mapInstance.addSource('300015', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Shirvan',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [48.958163, 39.963442],
                [48.961312, 39.962696],
                [48.960191, 39.960226],
                [48.957078, 39.960982]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'shirvan_zone',
        type: 'fill',
        source: '300015',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // JABRAIL +
      mapInstance.addSource('300009', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Jabrail',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [47.024024, 39.402344],
                [47.024388, 39.399679],
                [47.023479, 39.399542],
                [47.023280, 39.399482],
                [47.023162, 39.399439],
                [47.022041, 39.398819],
                [47.021841, 39.398742],
                [47.021490, 39.398649],
                [47.021484, 39.398829],
                [47.021627, 39.399358],
                [47.021633, 39.399556],
                [47.021569, 39.399756],
                [47.021480, 39.399901],
                [47.021122, 39.400367],
                [47.021057, 39.400530],
                [47.021040, 39.400747],
                [47.021080, 39.400917],
                [47.021225, 39.401113],
                [47.021380, 39.401254],
                [47.021998, 39.401757],
                [47.022262, 39.402041],
                [47.022443, 39.402290]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'jabrail_zone',
        type: 'fill',
        source: '300009',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // Xocali +
      mapInstance.addSource('300018', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Xocali',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [46.800581, 39.906072],
                [46.800834, 39.904555],
                [46.799160, 39.903617],
                [46.798715, 39.904056],
                [46.798375, 39.904016],
                [46.798206, 39.904271],
                [46.798337, 39.904341],
                [46.798099, 39.904624]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'xocali_zone',
        type: 'fill',
        source: '300018',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // Yevlax +
      mapInstance.addSource('300014', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            name: 'Yevlax',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [47.159664, 40.597184],
                [47.160242, 40.597885],
                [47.160659, 40.598382],
                [47.160894, 40.598684],
                [47.160940, 40.598665],
                [47.161185, 40.598552],
                [47.161159, 40.598499],
                [47.160930, 40.597989],
                [47.160977, 40.597971],
                [47.160706, 40.597264],
                [47.160668, 40.597175],
                [47.161231, 40.597056],
                [47.161959, 40.596916],
                [47.161825, 40.596423],
                [47.160920, 40.59655],
                [47.159990, 40.596683],
                [47.160096, 40.597032],
                [47.159673, 40.597112],
                [47.159710, 40.597157]
              ]
            ]
          }
        }
      });

      mapInstance.addLayer({
        id: 'yevlax_zone',
        type: 'fill',
        source: '300014',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });

      // Fuzuli +
      mapInstance.addSource('300008', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: { name: 'Fuzuli' },
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [47.150185, 39.600664],
                    [47.154170, 39.599995],
                    [47.153735, 39.598742],
                    [47.149871, 39.599561],
                    [47.150185, 39.600664]
                  ]
                ]
              }
            },
            {
              type: 'Feature',
              properties: { name: 'Fuzuli' },
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [47.149727, 39.599051],
                    [47.153557, 39.598241],
                    [47.153225, 39.597310],
                    [47.152842, 39.597335],
                    [47.152493, 39.597360],
                    [47.152145, 39.597393],
                    [47.151797, 39.597436],
                    [47.151031, 39.597540],
                    [47.150128, 39.597719],
                    [47.149689, 39.597835],
                    [47.149412, 39.597903],
                    [47.149727, 39.599051]
                  ]
                ]
              }
            }
          ]
        }
      });

      mapInstance.addLayer({
        id: 'fuzuli_zone',
        type: 'fill',
        source: '300008',
        layout: {},
        paint: {
          'fill-color': '#FFB901',
          'fill-opacity': 0.6,
        }
      });
    });

    setMap(mapInstance);
  }, []);

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;

    if (map) {
      // Hide all regions first
      const allRegions = ['300001', '300004', '100001', '1', '200002', '300005', '300006', '300011', '300009', '300015', '300018', '300017', '300014', '300008', '300016', '300009'];
      allRegions.forEach((region) => {
        const regionElement = document.getElementById(region);
        if (regionElement) {
          regionElement.style.display = 'none';
        }
      });

      // Show selected region and fly to it
      if (selectedRegion !== '-') {
        const regionElement = document.getElementById(selectedRegion);
        if (regionElement) {
          regionElement.style.display = 'block';
        }

        const regionCoordinates = {
          '300004': [49.799079, 40.375360],   // yasamal +
          '100001': [49.795442, 40.371232],   // yasamal 2 +
          '300001': [50.066448, 40.378125],   // hovsan +
          '300005': [50.069394, 40.384067],   // hovsan 2 +
          '1': [46.375786, 40.681261],        // ganja +
          '300017': [49.675748, 40.605229],   // sumqait YK +
          '200002': [49.667094, 40.580933],   // sumqait 2 +
          '300011': [49.813536, 40.451634],   // binaqadi +
          '300009': [47.021569, 39.399756],   // jabrail +
          '300015': [48.960191, 39.960226],   // shirvan +
          '300016': [48.839397, 38.768968],   // lankaran +
          '300008': [47.149727, 39.599051],   // fuzuli +
          '300018': [46.798375, 39.904016],   // xocali +
          '300014': [47.160706, 40.597264],   // yevlax +
        };

        const { center, zoom } = {
          center: regionCoordinates[selectedRegion] || [47.912, 40.173],
          zoom: selectedRegion === '-' ? 7 : 14,
        };

        map.flyTo({
          center,
          zoom,
          bearing: 0,
          speed: 0.8,
          curve: 2,
          easing: (t) => t
        });
      } else {
        map.flyTo({
          center: [47.912, 40.173],
          zoom: 7,
          bearing: 0,
          speed: 0.8,
          curve: 2,
          easing: (t) => t
        });
      }
    }
  };

  return (
    <>
      {/* <h5 className='t-color1 fw-bolder' style={{ fontSize: "22px" }}>{t('projects:projects_list')}</h5> */}
      <div id="projects-map" className='position-relative'>
        <div className='proj-buttons w-100 d-flex justify-content-between position-absolute mt-5 px-3 top-0 left-0 z-3'>
          <select className="region-selector cursor ps-4" onChange={handleRegionChange}>
            <option value="-">Layihə</option>
            {projects.map(proj =>
              <option key={proj.id} value={proj.id}>{proj.name_az}</option>
            )}
          </select>
          <div className='d-flex position-relative proj-buttons mt-4 pe-4'>
            <Link to='/layiheler-xarite' className='btn btn-secondary me-3'>Xəritə üzrə</Link>
            <Link to='/layiheler' className='btn btn-secondary me-3'>Siyahı üzrə</Link>
          </div>
        </div>

        <div id="map" className='mb-0' style={{ width: '100%', height: '700px' }}></div>

        <div className='mapinfo-popup' id="300004" style={{ display: 'none' }}> {/*Yasamal*/}
          <p>
            Ərazinin ümumi sahəsi - 11,6 hektar <br />
            Yaşayış binalarının ümumi sayı - 29<br />
            12 mərtəbəli - 12 bina<br />
            9 mərtəbəli - 17 bina<br />
            Mənzillərin ümumi sayı – 1 843<br />
            1 otaqlı - 324 <br />
            2 otaqlı - 1052 <br />
            3 otaqlı - 467 <br />
            Mənzillərin ölçüləri:<br />
            1 otaqlı - 31,5 - 33,37 m² <br />
            2 otaqlı - 49,77- 57,87 m²<br />
            3 otaqlı - 68,86 - 70,46 m²<br />
            1 ümumtəhsil məktəbi (960 yerlik)<br />
            1 uşaq bağçası (240 yerlik)
          </p>
        </div>
        <div className='mapinfo-popup' id="300001" style={{ display: 'none' }}> {/*Hovsan*/}
          <p>
            Ərazinin ümumi sahəsi - 20 hektar<br />
            Yaşayış binalarının ümumi sayı - 11<br />
            7 mərtəbəli – 7 bina<br />
            10 mərtəbəli – 4 bina<br />
            Mənzillərin ümumi sayı – 2 962<br />
            1 otaqlı - 280<br />
            2 otaqlı  - 1 401<br />
            3 otaqlı - 980<br />
            4 otaqlı - 301<br />
            Mənzillərin ölçüləri:<br />
            1 otaqlı - 37,30 – 38,70 m²<br />
            2 otaqlı  - 49,22 – 67,39 m²<br />
            3 otaqlı - 68,13 – 95,02 m²<br />
            4 otaqlı - 78,67 – 106,18 m²<br />
            2 uşaq bağçası (90 yerlik)<br />
            1 ümumtəhsil məktəbi (1 920 yerlik)<br />
            1 tibb mərkəzi
          </p>
        </div>
        <div className='mapinfo-popup' id="300005" style={{ display: 'none' }}> {/*Hovsan2*/}
          <p>
            Ərazinin ümumi sahəsi - 18,81 hektar<br />
            Yaşayış binalarının ümumi sayı - 60<br />
            9 mərtəbəli - 36 bina<br />
            12  mərtəbəli - 24 bina<br />
            Mənzillərin ümumi sayı – 2 950<br />
            Studio tipli – 192<br />
            2 otaqlı – 414<br />
            3 otaqlı – 1 722<br />
            4 otaqlı – 606<br />
            5 otaqlı – 16<br />
            Mənzillərin ölçüləri:<br />
            Studio tipli – 40,1 m²<br />
            2 otaqlı – 57,68 – 57,70 m²<br />
            3 otaqlı – 72,82 – 76,71 m²<br />
            4 otaqlı – 98,7 – 114,61 m²<br />
            5 otaqlı – 156,48 – 156,52 m²<br />
            1 ümumtəhsil məktəbi (1 920 yerlik)<br />
            1 uşaq bağçası (240 yerlik)
          </p>
        </div>
        <div className='mapinfo-popup' id="100001" style={{ display: 'none' }}>{/*Yasamal2*/}
          <p>
            Ərazinin ümumi sahəsi - 15,4 hektar <br />
            Yaşayış binalarının ümumi sayı - 40<br />
            9 mərtəbəli - 22 bina<br />
            12 mərtəbəli - 18 bina<br />
            Mənzillərin ümumi sayı – 2 200<br />
            1 otaqlı - 144<br />
            Studio tipli – 156<br />
            2 otaqlı – 752<br />
            3 otaqlı – 969<br />
            4 otaqlı – 176<br />
            5 otaqlı – 2<br />
            6 otaqlı - 1<br />
            Mənzillərin ölçüləri:<br />
            1 otaqlı - 35,46 – 35,57 m²<br />
            Studio tipli – 33,52 – 35,00 m²<br />
            2 otaqlı – 55,91 – 60,02 m²<br />
            3 otaqlı – 72,55 – 76,79 m²<br />
            4 otaqlı – 96,10 – 96,67 m²<br />
            5 otaqlı – 142,72 – 142,78 m²<br />
            6 otaqlı – 178,64 m²<br />
            1 ümumtəhsil məktəbi (1 200 yerlik)<br />
            2 ədəd (125 yerlik), 1 ədəd (100 yerlik) uşaq bağçası
          </p>
        </div>
        <div className='mapinfo-popup' id="1" style={{ display: 'none' }}> {/*Gence*/}
          <p>
            Ərazinin ümumi sahəsi - 6,23 hektar<br />
            Yaşayış binalarının ümumi sayı - 18<br />
            9 mərtəbəli 12 bina<br />
            12  mərtəbəli 6 bina<br />
            Mənzillərin ümumi sayı – 986<br />
            1 otaqlı - 48<br />
            Studio tipli – 68<br />
            2 otaqlı – 318<br />
            3 otaqlı – 466<br />
            4 otaqlı – 86<br />
            Mənzillərin ölçüləri:<br />
            1 otaqlı - 35,46 – 35,57 m²<br />
            Studio tipli – 34,85 – 34,97 m²<br />
            2 otaqlı – 56,40 – 56,86 m²<br />
            3 otaqlı – 72,55 – 76,79 m²<br />
            4 otaqlı – 96,10 – 96,67 m²<br />
            1 ümumtəhsil məktəbi (1 176 yerlik)<br />
            1 uşaq bağçası (125 yerlik)
          </p>
        </div>
        <div className='mapinfo-popup' id="300017" style={{ display: 'none' }}> {/*Sumqayit YK*/}
          <p>
            Ərazinin ümumi sahəsi - 2 hektar<br />
            Yaşayış binalarının ümumi sayı - 11<br />
            9 mərtəbəli - 6 bina<br />
            12  mərtəbəli 4 bina<br />
            15 mərtəbəli - 1 bina<br />
            Mənzillərin ümumi sayı – 461<br />
            Studio tipli – 13<br />
            2 otaqlı – 113<br />
            3 otaqlı – 279<br />
            4 otaqlı – 56<br />
            Mənzillərin ölçüləri:<br />
            Studio tipli – 50,2 m²<br />
            2 otaqlı – 59,7 - 59,9 m²<br />
            3 otaqlı – 77,1 – 82,4 m²<br />
            4 otaqlı – 104,4 - 108,3 m²<br />
            1 uşaq bağçası (80 yerlik)
          </p>
        </div>
        <div className='mapinfo-popup' id="200002" style={{ display: 'none' }}> {/*Sumqayit 2*/}
          <p>
            Ərazinin ümumi sahəsi - 0,75 hektar <br />
            Yaşayış binalarının ümumi sayı - 2<br />
            9 mərtəbəli - 2 bina<br />
            Mənzillərin ümumi sayı - 144<br />
            Studio tipli - 18 <br />
            1 otaqlı - 18<br />
            2 otaqlı - 72<br />
            3 otaqlı - 36<br />
            Mənzillərin ölçüləri:<br />
            Studio tipli - 34,83 m²<br />
            1 otaqlı - 35,56 m² <br />
            2 otaqlı - 56,53 – 56,62 m²<br />
            3 otaqlı - 74,43 – 74,57 m²<br />
          </p>
        </div>
        <div className='mapinfo-popup' id="300011" style={{ display: 'none' }}> {/*Binaqadi*/}
          <p>Ərazinin ümumi sahəsi - 9 hektar <br />
            Yaşayış binalarının ümumi sayı - 28<br />
            9 mərtəbəli - 16 bina<br />
            12 mərtəbəli - 12 bina<br />
            Mənzillərin ümumi sayı - 1398<br />
            Studio tipli - 32  <br />
            2 otaqlı - 234<br />
            3 otaqlı - 850<br />
            4 otaqlı - 266<br />
            5 otaqlı - 16<br />
            Mənzillərin ölçüləri:<br />
            Studio tipli - 40,10 m²<br />
            2 otaqlı - 55,98 - 57,70 m²<br />
            3 otaqlı - 71,19 - 76,71 m²<br />
            4 otaqlı - 98,70 - 114,61 m²<br />
            5 otaqlı - 156,48 - 156,52 m²<br />
            1 uşaq bağçası (80 yerlik)<br />
            1 ümumtəhsil məktəbi (960 yerlik)
          </p>
        </div>
        <div className='mapinfo-popup' id="300015" style={{ display: 'none' }}> {/*Shirvan*/}
          <p>
            Ərazinin ümumi sahəsi - 8,11 hektar <br />
            Yaşayış binalarının ümumi sayı - 25<br />
            9 mərtəbəli - 14 bina<br />
            12 mərtəbəli - 11 bina<br />
            Mənzillərin ümumi sayı – 1 264<br />
            Studio tipli - 32<br />
            2 otaqlı - 216<br />
            3 otaqlı - 768<br />
            4 otaqlı - 248<br />
            Mənzillərin ölçüləri:<br />
            Studio tipli - 40,10 m²<br />
            2 otaqlı - 57,68 - 57,70 m²<br />
            3 otaqlı - 72,82 - 76,71 m²<br />
            4 otaqlı - 98,70 - 114,61 m²<br />
            1 uşaq bağçası (80 yerlik)<br />
            1 ümumtəhsil məktəbi (960 yerlik)
          </p>
        </div>
        <div className='mapinfo-popup' id="300009" style={{ display: 'none' }}> {/*Jabrail*/}
          <p>
            Ərazinin ümumi sahəsi - 7,87 hektar <br />
            Yaşayış binalarının ümumi sayı - 33<br />
            5 mərtəbəli - 7 bina<br />
            4 mərtəbəli - 26 bina<br />
            Mənzillərin ümumi sayı - 712<br />
            1 otaqlı - 8<br />
            2 otaqlı  - 136<br />
            2 otaqlı studio tipli - 8<br />
            3 otaqlı - 392<br />
            4 otaqlı - 168<br />
            Mənzillərin ölçüləri:<br />
            1 otaqlı - 45 – 50 m² <br />
            2 otaqlı  - 55 - 90 m²<br />
            2 otaqlı studio tipli - 98-100 m²<br />
            3 otaqlı - 90-108 m²<br />
            4 otaqlı - 110-120 m²
          </p>
        </div>
        <div className='mapinfo-popup' id="300008" style={{ display: 'none' }}> {/*Fuzuli*/}
          <p>
            Ərazinin ümumi sahəsi - 8,9 hektar<br />
            Yaşayış binalarının ümumi sayı - 38<br />
            4 mərtəbəli - 2 bina<br />
            4-5 mərtəbəli - 10 bina  <br />
            5 mərtəbəli - 16 bina  <br />
            6 mərtəbəli - 4 bina <br />
            5-6 mərtəbəli - 2 bina <br />
            6-7 mərtəbəli - 4 bina <br />
            Mənzillərin ümumi sayı - 846<br />
            2 otaqlı - 318<br />
            3 otaqlı - 430<br />
            4 otaqlı - 98<br />
            Mənzillərin ölçüləri:<br />
            2 otaqlı - 54,2 - 68,3 m²<br />
            3 otaqlı - 71,6- 109,3 m²<br />
            4 otaqlı - 92,8 - 108,4 m²
          </p>
        </div>
        <div className='mapinfo-popup' id="300016" style={{ display: 'none' }}>  {/*Lankaran*/}
          <p style={{ lineHeight: '1.7rem' }}>
            Ərazinin ümumi sahəsi - 1,5 hektar<br />
            Yaşayış binalarının ümumi sayı - 5<br />
            9 mərtəbəli - 5 bina<br />
            Mənzillərin ümumi sayı – 240<br />
            Studio tipli – 32<br />
            2 otaqlı – 36<br />
            3 otaqlı – 104<br />
            4 otaqlı – 68<br />
            Mənzillərin ölçüləri:<br />
            Studio tipli – 40,1 m²<br />
            2 otaqlı – 57,7 m²<br />
            3 otaqlı – 72,9 – 76,7 m²<br />
            4 otaqlı – 99 – 114,6 m²
          </p>
        </div>
        <div className='mapinfo-popup' id="300014" style={{ display: 'none' }}>{/*Yevlax*/}
          <p>
            Ərazinin ümumi sahəsi - 1,9 hektar<br />
            Yaşayış binalarının ümumi sayı - 6<br />
            7 mərtəbəli - 6 bina<br />
            Mənzillərin ümumi sayı - 329<br />
            Studio tipli - 14  <br />
            1 otaqlı - 28<br />
            2 otaqlı - 84<br />
            3 otaqlı - 139<br />
            4 otaqlı - 64	<br />
            Mənzillərin ölçüləri: <br />
            Studio tipli - 43,10 - 43,46 m²<br />
            1 otaqlı - 45,43 - 45,45 m²<br />
            2 otaqlı  - 58,57 - 86,63 m²<br />
            3 otaqlı - 73,93 – 98,06 m²<br />
            4 otaqlı - 102,38 - 129,15 m²
          </p>
        </div>
        <div className='mapinfo-popup' id="300018" style={{ display: 'none' }}>{/*Xocali*/}
          <p>
            Ərazinin ümumi sahəsi - 3,34 hektar<br />
            Yaşayış binalarının ümumi sayı - 15<br />
            3 mərtəbəli - 15 bina<br />
            Mənzillərin ümumi sayı - 180<br />
            2 otaqlı - 36<br />
            3 otaqlı - 90<br />
            4 otaqlı - 54<br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Map;